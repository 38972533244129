  .theme2 .section-title h2{
      font-size:2.8rem;
      padding-bottom: 15px;
      margin-bottom: 30px;
  }
  
  .theme2 .section-title{
      position:relative;
      text-align: left;
  }
  
  .navbar-reduce{
      transition: all .5s ease-in-out;
      padding-top: 19px;
      padding-bottom: 19px;
      position:fixed;
  }
  
  .nav-pills-a.nav-pills .nav-link {
    color: #000000;
    position: relative;
    font-weight: 600;
  }
  
  .nav-pills-a.nav-pills .nav-link.active {
    background-color: transparent;
  }
  
  .nav-pills-a.nav-pills .nav-link.active:after {
    content: '';
    position: absolute;
    left: 0;
    bottom: -1px;
    width: 100%;
    height: 2px;
    background-color: #2eca6a;
    z-index: 2;
  }
  
  .a {
    color: #2eca6a;
    font-size: 1.3rem;
  }
  
  .theme2 section{padding:100px 45px;}
  
  
  /* Shortcode */
  .nowrap { white-space:nowrap;}
  .no-float { float:none;}
  .font-blue-theme { font-size:23px !important; color:#09b2dc; line-height:1;}
  .mr-10 { margin-right:10px;}
  .slimScrollDiv { bottom:23px;}
  .xs-only { display:none;}
  .price-tab-left .nav-tabs .nav-link { outline:none;}
  .py-32 { padding:32px 0px; }
  .mr-10 { margin-right:10px;}
  .p-3 { padding: 1.4rem!important; }
  .mb-0 { margin-bottom:0px !important;}
  .mt-32 { margin-top:32px; }
  .mt-50 { margin-top:50px; }
  
  .panel-group .panel+.panel {
      margin-top: 5px;
  }
  
  .panel-group .panel {
      margin-bottom: 0;
      border-radius: 4px;
  }
  
  .panel-group {
      margin-bottom: 0px;
  }
  
  .panel-group .panel {
      margin-bottom: 0;
      border-radius: 4px;
      border-color: #ddd;
  }
  
  .panel-default {
      border-color: #ddd;
  }
  
  .theme1 .panel {
      margin-bottom: 20px;
      background-color: #fff;
      border: 1px solid transparent;
      border-radius: 4px;
      -webkit-box-shadow: 0 1px 1px rgba(0, 0, 0, .05);
      box-shadow: 0 1px 1px rgba(0, 0, 0, .05);
  }
  
  .panel-default>.panel-heading {
      color: #333;
      background-color: #f5f5f5;
      border-color: #ddd;
  }
  
  .panel-group .panel-heading {
      border-bottom: 0;
  }
  
  .panel-heading {
      padding: 10px 15px;
      border-bottom: 1px solid transparent;
      border-top-left-radius: 3px;
      border-top-right-radius: 3px;
  }
  
  .panel-title {
      font-size: 1rem;
      margin-bottom: 0px;
  }
  
  /* Back to top buttosn */
  
  .back-to-top {
    position: fixed;
    display: none;
    background: #09b2dc;
    color: #fff;
    width: 44px;
    height: 44px;
    text-align: center;
    line-height: 1;
    font-size: 16px;
    border-radius: 50%;
    right: 15px;
    bottom: 15px;
    transition: background 0.5s;
    z-index: 11;
  }
  
  .back-to-top i {
    padding-top: 12px;
    color: #fff;
  }
  
  /* Prelaoders */
  
  #preloader {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 9999;
    overflow: hidden;
    background: #fff;
  }
  
  #preloader:before {
    content: "";
    position: fixed;
    top: calc(50% - 30px);
    left: calc(50% - 30px);
    border: 6px solid #f2f2f2;
    border-top: 6px solid #09b2dc;
    border-radius: 50%;
    width: 60px;
    height: 60px;
    -webkit-animation: animate-preloader 1s linear infinite;
    animation: animate-preloader 1s linear infinite;
  }
  
  @-webkit-keyframes animate-preloader {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
  
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  
  @keyframes animate-preloader {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
  
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  
  /* navbar */
  
  .navbar-top{
      background:#012341;
      width:100%;
  }
  .header-nav p{
      color:#fff;
      padding: 10px 45px;
  }
  .text-brand img{padding-right:5px;}
  .navbar-default {
    transition: all .5s ease-in-out;
    background-color: #ffffff;
    padding: 13px 45px 10px 45px;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
  }
  
  .navbar-default .nav-search {
    color: #000000;
    font-size: 1.5rem;
  }
  
  .navbar-default.navbar-reduce {
    box-shadow: 1px 2px 15px rgba(100, 100, 100, 0.3);
  }
  
  .navbar-default.navbar-trans,
  .navbar-default.navbar-reduce {
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
  }
  
  .navbar .themenavbar ul li.nav-item{
    position: relative;
    padding-right: 35px;
    padding-bottom: 8px;
    margin-left: 0;
  }
  
  .navbar .themenavbar ul li.nav-item a:hover{
      color:#09b2dc;
  }
  
  .navbar .themenavbar ul li.active a{color:#00b0db;}
  
  .navbar .themenavbar ul li.nav-item:last-child{padding-right:0px;}
  
  .navbar-default.navbar-trans .nav-link,
  .navbar-default.navbar-reduce .nav-link {
    font-size: 0.9em;
    color: #000000;
    font-weight: 500;
    letter-spacing: 0.030em;
    transition: all 0.1s ease-in-out;
    position: relative;
    padding-left: 0;
    padding-right: 0;
  }
  
  /*.navbar-default.navbar-trans .nav-link:before,
  .navbar-default.navbar-reduce .nav-link:before {
    content: '';
    position: absolute;
    bottom: 5px;
    left: 0;
    width: 100%;
    height: 2px;
    z-index: 0;
    background-color: #2eca6a;
    -webkit-transform: scaleX(0);
    transform: scaleX(0);
    -webkit-transform-origin: right;
    transform-origin: right;
    transition: opacity .2s ease-out 0.3s, -webkit-transform .2s ease-out;
    transition: transform .2s ease-out, opacity .2s ease-out 0.3s;
    transition: transform .2s ease-out, opacity .2s ease-out 0.3s, -webkit-transform .2s ease-out;
  }*/
  
  
  .navbar-default.navbar-trans .nav-link:hover:before,
  .navbar-default.navbar-reduce .nav-link:hover:before {
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
    -webkit-transform-origin: left;
    transform-origin: left;
  }
  
  .navbar-default.navbar-trans .show > .nav-link:before,
  .navbar-default.navbar-trans .active > .nav-link:before,
  .navbar-default.navbar-trans .nav-link.show:before,
  .navbar-default.navbar-trans .nav-link.active:before,
  .navbar-default.navbar-reduce .show > .nav-link:before,
  .navbar-default.navbar-reduce .active > .nav-link:before,
  .navbar-default.navbar-reduce .nav-link.show:before,
  .navbar-default.navbar-reduce .nav-link.active:before {
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
  }
  
  /*.navbar-default.navbar-trans .nav-link:before {
    background-color: #2eca6a;
  }*/
  
  .navbar-default.navbar-trans .nav-link:hover {
    color: #000000;
  }
  
  .navbar-default.navbar-reduce {
    transition: all .5s ease-in-out;
    padding-top: 19px;
    padding-bottom: 19px;
    z-index: 9;
    width: 100%;
    top: 0px;
  }
  
  .navbar-default.navbar-reduce .nav-link {
    color: #000000;
  }
  
  .navbar-default.navbar-reduce .nav-link:before {
    background-color: #2eca6a;
  }
  
  .navbar-default.navbar-reduce .nav-link:hover {
    color: #000000;
  }
  
  .navbar-default.navbar-reduce .show > .nav-link,
  .navbar-default.navbar-reduce .active > .nav-link,
  .navbar-default.navbar-reduce .nav-link.show,
  .navbar-default.navbar-reduce .nav-link.active {
    color: #000000;
  }
  
  .navbar-default.navbar-reduce .navbar-brand {
    color: #000000;
  }
  
  .navbar-default .dropdown .dropdown-menu {
    border-top: 0;
    border-left: 4px solid #2eca6a;
    border-right: 0;
    border-bottom: 0;
    -webkit-transform: translate3d(0px, -40px, 0px);
    transform: translate3d(0px, -40px, 0px);
    opacity: 0;
    filter: alpha(opacity=0);
    visibility: hidden;
    transition: all 0.5s cubic-bezier(0.3, 0.65, 0.355, 1) 0s, opacity 0.31s ease 0s, height 0s linear 0.36s;
    margin: 0;
    border-radius: 0;
    padding: 12px 0;
  }
  
  .navbar-default .dropdown .dropdown-menu .dropdown-item {
    padding: 12px 18px;
    transition: all 500ms ease;
    font-weight: 600;
    min-width: 220px;
  }
  
  .navbar-default .dropdown .dropdown-menu .dropdown-item:hover {
    background-color: #ffffff;
    color: #2eca6a;
    transition: all 500ms ease;
  }
  
  .navbar-default .dropdown .dropdown-menu .dropdown-item.active {
    background-color: #ffffff;
    color: #2eca6a;
  }
  
  .navbar-default .dropdown:hover .dropdown-menu {
    -webkit-transform: translate3d(0px, 0px, 0px);
    transform: translate3d(0px, 0px, 0px);
    visibility: visible;
    opacity: 1;
    filter: alpha(opacity=1);
  }
  
  /* Hamburger Navbar */
  
  .navbar-toggler {
    position: relative;
  }
  
  .navbar-toggler:focus,
  .navbar-toggler:active {
    outline: 0;
  }
  
  .navbar-toggler span {
    display: block;
    background-color: #000000;
    height: 3px;
    width: 25px;
    margin-top: 4px;
    margin-bottom: 4px;
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    left: 0;
    opacity: 1;
  }
  
  .navbar-toggler span:nth-child(1),
  .navbar-toggler span:nth-child(3) {
    transition: -webkit-transform .35s ease-in-out;
    transition: transform .35s ease-in-out;
    transition: transform .35s ease-in-out, -webkit-transform .35s ease-in-out;
  }
  
  .navbar-toggler:not(.collapsed) span:nth-child(1) {
    position: absolute;
    left: 12px;
    top: 10px;
    -webkit-transform: rotate(135deg);
    transform: rotate(135deg);
    opacity: 0.9;
  }
  
  .navbar-toggler:not(.collapsed) span:nth-child(2) {
    height: 12px;
    visibility: hidden;
    background-color: transparent;
  }
  
  .navbar-toggler:not(.collapsed) span:nth-child(3) {
    position: absolute;
    left: 12px;
    top: 10px;
    -webkit-transform: rotate(-135deg);
    transform: rotate(-135deg);
    opacity: 0.9;
  }
  
  /* header area end */
  
  /* sectio-banner area start */
  .slider{
      header:500px;
  }
  
  .home-slider-banner .slick-slide img {
    border-radius: 0px;
  }
  
  
  .slideshow {
    position: relative;
    z-index: 1;
    height: 100%;
    max-width: 100%;
    margin: 0px auto;
  }
  
  
    .slider-track {
      transition: all 1s cubic-bezier(0.7, 0, 0.3, 1);
    }
  
    .item {
      height: 100%;
      position: relative;
      z-index: 1;
    }
      .banner .slick-slide{
          height:auto !important;
      }
  .banner .slick-slide .slick-active img{
      box-shadow: 0px 0px 0px 0px rgba(0,0,0,0.25);
      transform: scale(1);
  }
  .banner .slick-list .slick-active .slick-slide .slick-active img{
      box-shadow: 0px 0px 0px 0px rgba(0,0,0,0.25);
      transform: scale(1);
  }
  .banner .slick-list .slick-slide img {
      width: 1400px;
      height: 450px;
      object-fit: cover;
      margin-left: 15px;
      box-shadow: 0px 0px 0px 0px rgba(0,0,0,0.25);
      margin-top:-5px;
  
  }
  .banner {
      width: 100%;
      margin-left: auto;
      margin-right: auto;
      max-width: initial;
  }
  .banenr-text{
      position:absolute;
      right: 50px;
      bottom: 200px;
      width: 30%;
      z-index: 1;
      text-align: right;
  }
  .banner-text{
      position: absolute;
      z-index: 1;
      width: 30%;
      right: 60px;
      bottom: 230px;
      text-align: right;
  }
  .banner-text h2{
      font-weight: bold;
      text-shadow: 0 4px 11px #383838, 0 -3px 6px #383838;
      color:#fff;
      line-height: 40px;
      position:absolute;
      white-space: nowrap; overflow: hidden !important; text-overflow: ellipsis;width: 410px;
  }
  
  .banner-text h3 {
      font-weight: bold;
      text-shadow: 0 4px 11px #383838, 0 -3px 6px #383838;
      color:#fff;
      line-height: 40px;
      position:relative;
      top:41px;
      white-space: nowrap; overflow: hidden !important; text-overflow: ellipsis;width: 410px;
  }
  
  .banner-text p{
      color:#000;
      font-size:15px;
      text-shadow: 6px 8px 20px rgba(255, 255, 255, 0.4);
  }
  .offers .slick-slide img{
      width: 90%;
      height: auto !important;
      transform: scale(1);
      margin-left:0px;
  }
  
  
  /*banner single search*/ 
  
  .theme2 .single-search-blk h1{ font-size:36px; color:#000; line-height:1.3em; font-weight:400; margin:0; padding:0 0 20px;}
  .theme2 .single-search-blk h1 span{ font-weight:600;}
  .theme2 .single-search-blk p{ font-size:18px; line-height:1.8em; margin-bottom:42px;}
  .theme2 .single-search-blk .postcodeform{ width:95%; float:left; border-radius:6px;}
  .theme2 .single-search-blk .postcodeform input[type=text]{ height:60px; width:70%; float:left; border:none; border-radius:6px 0 0 6px !important; font-size:18px; padding:0 20px; position:relative; transition:all 200ms linear;}
  .theme2 .single-search-blk .postcodeform input[type=text]:focus{ box-shadow:0 0 30px rgba(13,138,218,0.5);}
  .theme2 .single-search-blk .postcodeform input[type=submit], .single-search-blk .postcodeform .btn{ height:60px; width:30%; float:left; border-radius:0 6px 6px 0; border:none; background:#0d8ada; color:#fff; text-transform:uppercase; font-size:20px; font-weight:600;margin:0}
  
  .custom-search-laundry{
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    transform: translate(-50%,-50%);
    z-index: 1;
  }
  
  .custom-search-laundry .single-search-blk {
    background-color: rgba(245, 245, 245, 0.86);
    box-shadow: 0px 11px 12px 0px rgba(0,0,0,0.25);
    height: 100%;
  }
  
  
  
  @media (min-width:1430px) and (max-width:1920px){
      .banner .slick-list .slick-slide img{
          width:1920px;
      }
  }
  
  section.section-banner{padding-top:0px;}
  .section-banner{
      width: 100%;
      position: relative;
      background-size: cover;
      padding:0px;
  }
  
  
  .custom-search-laundry .location-search{position:relative;background: #09b2dc;border-radius: 60px;}
  div.fancy-select {
      font-weight: bold;
      text-transform: uppercase;
      font-size: 13px;
      color: #46565D;
      width: 30%;
      position: absolute;
      top: 0px;
  }
  
  .location-search input{height: 60px;border: 0px;border-radius: 60px;line-height: 60px;padding-left: 70px;font-size:13px;font-weight:600;color: #fff;background: #09b2dc;}
  
  .location-search input:focus{background:#09b2dc;border:0px;box-shadow: 0px 11px 12px 0px rgba(0,0,0,0.25);color:#fff;}
  
  .location-search input.form-control::-webkit-input-placeholder { color: white; }
  .location-search input.form-control:-moz-placeholder { color: white; }
  .location-search input.form-control::-moz-placeholder { color: white; }
  .location-search input.form-control:-ms-input-placeholder { color: white; }
  .location-search input.form-control::-ms-input-placeholder { color: white; }
  .location-search img{
      position:absolute;
      top:15px;
      left:15px;
  }
  div.fancy-select.disabled {
      opacity: 0.5;
  }
  
  div.fancy-select select:focus + div.trigger.open {
      box-shadow: none;
  }
  
  div.fancy-select div.trigger {
      cursor: pointer;
      padding: 8px 20px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      position: relative;
      background: transparent;
      border: 0;
      border-top-color: currentcolor;
      border-top-color: #A5B2CB;
      color: #fff;
      box-shadow: 0;
      transition: all 240ms ease-out;
      -webkit-transition: all 240ms ease-out;
      -moz-transition: all 240ms ease-out;
      -ms-transition: all 240ms ease-out;
      -o-transition: all 240ms ease-out;
      border-radius: 10px;
      height: 60px;
      line-height: 45px;
  }
  
  div.fancy-select div.trigger:after {
      content: "";
      display: block;
      position: absolute;
      width: 0;
      height: 0;
      border: 5px solid transparent;
      border-top-color: #fff;
      top: 27px;
      right: 42px;
      color:#fff;
  }
  
  div.fancy-select div.trigger.open {
      background: transparent;
      border: 0px;
      color: #fff;
      box-shadow: none;
  }
  
  div.fancy-select div.trigger.open:after {
      border-top-color: #fff;
  }
  
  div.fancy-select ul.options {
      list-style: none;
      margin: 0;
      position: absolute;
      top: 40px;
      left: 0;
      visibility: hidden;
      opacity: 0;
      z-index: 50;
      max-height: 200px;
      overflow: auto;
      background: #fff;
      border-radius: 4px;
      width:100%;
      border-top: 1px solid #7DD8D2;
      box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
      transition: opacity 300ms ease-out, top 300ms ease-out, visibility 300ms ease-out;
      -webkit-transition: opacity 300ms ease-out, top 300ms ease-out, visibility 300ms ease-out;
      -moz-transition: opacity 300ms ease-out, top 300ms ease-out, visibility 300ms ease-out;
      -ms-transition: opacity 300ms ease-out, top 300ms ease-out, visibility 300ms ease-out;
      -o-transition: opacity 300ms ease-out, top 300ms ease-out, visibility 300ms ease-out;
      padding:0px;
      margin:0px;
      font-weight:100;
  }
  
  div.fancy-select ul.options {
      list-style: none;
      margin: 0;
      position: absolute;
      top: 40px;
      left: 0;
      visibility: hidden;
      opacity: 0;
      z-index: 50;
      max-height: 200px;
      overflow: auto;
      background: #fff;
      border-radius: 4px;
      width:100%;
      border-top: 1px solid #7DD8D2;
      box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
      transition: opacity 300ms ease-out, top 300ms ease-out, visibility 300ms ease-out;
      -webkit-transition: opacity 300ms ease-out, top 300ms ease-out, visibility 300ms ease-out;
      -moz-transition: opacity 300ms ease-out, top 300ms ease-out, visibility 300ms ease-out;
      -ms-transition: opacity 300ms ease-out, top 300ms ease-out, visibility 300ms ease-out;
      -o-transition: opacity 300ms ease-out, top 300ms ease-out, visibility 300ms ease-out;
      padding:0px;
      margin:0px;
      font-weight:100;
  }
  
  div.fancy-select ul.options.open {
      visibility: visible;
      top: 50px;
      opacity: 1;
      transition: opacity 300ms ease-out, top 300ms ease-out;
      -webkit-transition: opacity 300ms ease-out, top 300ms ease-out;
      -moz-transition: opacity 300ms ease-out, top 300ms ease-out;
      -ms-transition: opacity 300ms ease-out, top 300ms ease-out;
      -o-transition: opacity 300ms ease-out, top 300ms ease-out;
  }
  
  div.fancy-select ul.options.overflowing {
      top: auto;
      bottom: 40px;
  
      transition: opacity 300ms ease-out, bottom 300ms ease-out, visibility 300ms ease-out;
      -webkit-transition: opacity 300ms ease-out, bottom 300ms ease-out, visibility 300ms ease-out;
      -moz-transition: opacity 300ms ease-out, bottom 300ms ease-out, visibility 300ms ease-out;
      -ms-transition: opacity 300ms ease-out, bottom 300ms ease-out, visibility 300ms ease-out;
      -o-transition: opacity 300ms ease-out, bottom 300ms ease-out, visibility 300ms ease-out;
  }
  
  div.fancy-select ul.options.overflowing.open {
      top: auto;
      bottom: 50px;
  
      transition: opacity 300ms ease-out, bottom 300ms ease-out;
      -webkit-transition: opacity 300ms ease-out, bottom 300ms ease-out;
      -moz-transition: opacity 300ms ease-out, bottom 300ms ease-out;
      -ms-transition: opacity 300ms ease-out, bottom 300ms ease-out;
      -o-transition: opacity 300ms ease-out, bottom 300ms ease-out;
  }
  
  div.fancy-select ul.options li {
      padding: 8px 12px;
      color: #2B8686;
      cursor: pointer;
      white-space: nowrap;
      transition: all 150ms ease-out;
      -webkit-transition: all 150ms ease-out;
      -moz-transition: all 150ms ease-out;
      -ms-transition: all 150ms ease-out;
      -o-transition: all 150ms ease-out;
  }
  
  div.fancy-select ul.options li.selected {
      background: #00b0db;
      color: #fff;
  }
  ul.options, .product-selection ul  {
      padding:0px;
      margin:0px;
  }
  
  .product-selection ul a li{
      display: block;
      padding: 10px 15px;
      background:#fff;
      border-radius: 50px;
      height: 60px;
      line-height: 40px;
      margin: 20px 0;
      box-shadow: 0px 10px 21px 1px rgba(0,0,0,0.15);
  }
  .product-selection ul a{
      font-size: 16px;
      font-weight: 500;
      color:#616161;
  }
  .product-selection ul a li span.search-title{
      padding-left:20px;
      white-space: nowrap;
      overflow: hidden !important;
      text-overflow: ellipsis;
      width: 167px;
      display: inline-block;
      vertical-align: middle;
  }
  .product-selection ul a li span img.booking-img{width:30px;}
  
  
  /* section banner area end */
  
  /* offer section */
  .morecontent span {
      display: none;
  }
  .morelink {
      display: block;
  }
  .morecontent a{color: #09b2dc;font-weight: 600;}
  section.offer-section{
      padding:15px 0;
      margin-top:-45px;
  }
  .sec-offer strong{
      color:#09b2dc;
      font-size:26px;
      padding-right: 5px;
  }
  .sec-offer span a{
      font-size: 16px;
      font-weight: 500;
  }
  .sec-offer span a i{
      font-size: 16px;
      padding-left: 8px;
  }
  .product-selection ul a li span.proselection-right{
      text-align: right;
      font-size: 20px;
      padding-right: 4px;
  }
  /* offer section */
  
  /* about and service */
  .theme2 .section-title span{
      margin-bottom:5px;
      font-size:13px;
  }
  .theme2 .section-title h2 span{
      color:#09b2dc;
      font-size: 2.8rem;
  }
  
  .theme2 .section-title h2::before {
      bottom: 0;
      width: 70%;
      height: 2px;
      content: "";
      max-width: 200px;
      position: absolute;
  }
  
  .theme2 .section-title h2::after {
      bottom: 0;
      width: 30%;
      color: #09b2dc;
      height: 2px;
      display: table;
      content: "....";
      max-width: 34px;
      font-size: 24px;
      line-height: 2px;
      position: absolute;
      letter-spacing: 5px;
      vertical-align: middle;
      right: 23px;
  }
  .about-service p{
      color: #868686;
      font-size: 16px;
      line-height: 25px;
  }
  .about-service h3{
      line-height: 40px;
      color:#002240;
  }
  .about-service{
      max-width:90%;
  }
  /* about and service */
  
  /* price section */
  
  
  .pricelist-slider .slick-prev:before, .pricelist-slider .slick-next:before,
  .pricelist-tab-slider .slick-prev:before, .pricelist-tab-slider .slick-next:before {
      color:#000000;
  }
  
  .pricelist-slider .slick-prev,
  .pricelist-slider .slick-next,
  .pricelist-slider .slick-prev:hover,
  .pricelist-slider .slick-prev:focus,
  .pricelist-slider .slick-next:hover,
  .pricelist-slider .slick-next:focus,
  .pricelist-tab-slider .slick-prev,
  .pricelist-tab-slider .slick-next,
  .pricelist-tab-slider .slick-prev:hover,
  .pricelist-tab-slider .slick-prev:focus,
  .pricelist-tab-slider .slick-next:hover,
  .pricelist-tab-slider .slick-next:focus {
      display:block !important;
      width:50px;
      height:50px;
      background:#ffffff;
  }
  
  .pricelist-slider .slick-next {margin-top:0px;}
  
  
  .pricelist-tab-slider .slick-prev { left:0px;}
  .pricelist-tab-slider .slick-next { right:0px; margin-top:0px;}
  .pricelist-tab-slider .slick-prev:before, .pricelist-tab-slider .slick-next:before { font-size:23px; font-weight:bolder; }
  
  .pricelist-tab-slider .slick-prev,
  .pricelist-tab-slider .slick-next,
  .pricelist-tab-slider .slick-prev:hover,
  .pricelist-tab-slider .slick-prev:focus,
  .pricelist-tab-slider .slick-next:hover,
  .pricelist-tab-slider .slick-next:focus {
      background:transparent;
      box-shadow:none;
  }
  
  .theme2 .price-section{
      padding:80px 45px 0px;
  }
  
  .theme2 .price-section .nav-tabs .nav-item {
      padding:14px 23px !important;
  }
  
  .theme2 .price-section h2 {
      float: left;
      position: relative;
      left: 50%;
      transform: translateX(-50%);
  }
  
  
  .price-tab-left .nav.nav-tabs {
      margin-right: 20px;
      border-bottom:0;
      border-right: 1px solid #ddd;
      padding-right: 15px;
  }
  .price-tab-left .nav-tabs .nav-link {
      border: 1px solid transparent;
      border-top-left-radius: .25rem;
      border-top-right-radius: .25rem;
      background: transparent;
  }
  
  .price-tab-left .nav-tabs .nav-link.active {
      color: #fff;
      background-color: #00B1F7;
      border-right: 0;
      border-top: 0;
      border-bottom: 0;
      border-left: 0px solid #007bff;
      box-shadow: 0px 10px 30px 0px rgba(0, 177, 247, 0.50);
      border-radius: 32px;
  
  }
  
  .price-tab-left .nav-tabs .nav-link {
      border: 0px solid transparent;
      padding: 14px 23px;
  }
  
  .price-tab-left .nav-tabs .nav-link span {
      white-space: nowrap;
      overflow: hidden !important;
      text-overflow: ellipsis;
      width: 158px;
      display: inline-block;
      vertical-align: middle;
  }
  
  
  .price-tab-left .nav.nav-tabs {
      width:95%;
      margin-right: 0px;
      border-bottom: 0;
      border-right: 0px solid transparent;
      padding-right: 0px;
      float: left;
      position: relative;
      left: 50%;
      transform: translateX(-50%);
      background: #D9F3FE;
      border-radius: 50px;
      padding: 0px 50px;
  }
  .price-tab-left{
      width:100%;
      float:none;
  }
  .price-tab-left .nav.nav-tabs li.nav-item a img{
      padding-right: 7px;
  }
  
  .price-tab-right{
      position:relative;
      width: 100%;
      float: left;
      margin-top: -140px;
      min-height:500px;
      border-radius: 10px;
      bottom:-113px;
  
  }
  
  .price-tab-right .slick-list{ padding-top:50px; }
  
  
  .price-tab-right .slick-list .slick-track {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
  }
  
  
  .price-tab-right .slick-track img { width:auto !important; display:inline-block !important; box-shadow:none !important; }
  
  
  
  .pricelist-tab-slider .slick-track img { width:auto !important; display:inline-block !important; box-shadow:none !important; transform: none; }
  
  
  .nav-tabs.nav-justified {
      width: 100%;
      border-bottom: 0;
  }
  
  .price-list-details .panel-heading { padding:23px 14px; }
  .price-list-details .panel-body { padding:0px 10px; }
  .price-list-details .list-group { margin-bottom: 0px; }
  
  
  .price-list-tab-blk { border-top:1px solid #ccc; border-bottom: 1px solid #ccc; }
  
  
  .price-list-tab-blk .nav-tabs.nav-justified li a.active, .price-list-tab-blk .nav-tabs.nav-justified li a:focus, .price-list-tab-blk .nav-tabs.nav-justified li a:hover {
    border:0px solid transparent;
    background-color: #0d8ada !important;
    color:#ffffff !important;
    border-radius: 0px;
  }
  
  .price-list-tab-blk .nav-tabs.nav-justified>li>a{
    border:0px solid transparent;
    border-bottom: 0px solid transparent;
    border-radius: 0px;
    padding:23px 0px;
    font-size: 18px;
  }
  
  .price-list-tab-blk .nav-tabs.nav-justified>li {
    border-right:1px solid #ddd;
  }
  
  .price-list-details .panel-title > a.arrow-style:before {
      font-size: 32px;
      line-height: 19px;
      float: right !important;
      font-family: FontAwesome;
      font-weight: normal;
      content:"\f106";
      padding-right: 5px;
  }
  
  
  .price-list-details .panel-title .custom-truncate {
      display: inline-block;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      /*width: 410px;*/
  }
  
  .theme2 .price-section .panel-group {
    margin-bottom: 10px;
  }
  
  .price-list-details .panel-title > a.arrow-style.collapsed:before {
      float: right !important;
      content:"\f107";
      font-weight: normal;
      line-height: 14px;
  }
  
  .price-list-details .panel-title span {
    margin-right: 10px;
    vertical-align: middle; }
  
  .price-list-details > a:hover,
  .price-list-details > a:active,
  .price-list-details > a:focus  {
      text-decoration:none;
  }
  
  .price-list-tab-blk .nav { display: block !important; }
  
  .price-list-tab-blk .nav>li>a { display: block; position: relative; text-align: center; }
  
  .price-list-tab-blk ul li:first-child a{ border-radius: 5px 0px 0px 0px !important; }
  .price-list-tab-blk ul li:last-child a{ border-radius: 0px 5px 0px 0px !important; }
  
  .theme2 .price-section .list-group-item { border:0px solid transparent; border-bottom: 1px solid #ddd; margin-bottom: 0; padding:23px 0px; }
  
  .theme2 .price-section .btn-group-lg>.btn, .price-section .btn-lg {
    padding: .5rem 1.67rem;
    font-size: 1.0rem;
    line-height: 1.5;
    /*margin-bottom: 14px;*/
    border-radius: 0rem;
  }
  
  .price-list-items .list-inline, .price-list-items .list-inline a {
    margin-bottom: 0px;
  }
  
  .theme2 .price-section .list-inline-item:not(:last-child) {
    margin-right: 0rem;
  }
  
  .best-dryclean-header{
      text-align: center;
      position:relative;
      height:auto;
  }
  .laundry-details{
      max-width: 99%;
      margin: 0px 0 20px;
      border: 1px solid #f0f0f0;
  }
  .best-dryclean-header p{
      position: absolute;
      font-size: 15px;
      font-weight: 600;
      padding: 14px 0px;
      top: 50%;
      width: 100%;
  }
  .best-dryclean-header img{
      width: 80px;
      border-radius: 100%;
      height: 80px;
      text-align: center;
      margin: 00% auto 0px;
      position: relative;
      background:#ffffff;
      box-shadow: -2px 15px 9px -3px rgba(0,0,0,0.25);
      top: -50%;
      transform: translateY(-50%);
  }
  
  .best-dryclean-header:before {
      position: absolute;
      content: "";
      background: #ffffff;
      width: 41%;
      height: 100%;
      left: 50%;
      top: -50%;
      transform: translateX(-50%);
      border-radius: 50%;
  }
  
  .best-dryclean-header:before {
      position: absolute;
      content: "";
      background: #ffffff;
      width: 41%;
      height: 100%;
      left: 50%;
      top: -50%;
      transform: translateX(-50%);
      border-radius: 50%;
  }
  
  
  .drclean-header{
      background:#f0f0f0;
      position:relative;
      overflow:hidden;
  }
  .drclean-header span{
      font-size:14px;
      font-weight: 600;
       white-space: nowrap;
      overflow: hidden !important;
      text-overflow: ellipsis;
      width: 104px;
      display: inline-block;
      vertical-align: middle;
  }
  
  .drclean-header a {
      position:absolute;
      z-index:50;
      top:0px;
      right:0px;
  }
  
  .drclean-header .price-hrs { position:absolute; top:5px;right:5px;}
  .drclean-header .expand-collapse{ position:absolute; bottom:0px;right:0px; z-index:1; color:#ffffff; cursor:pointer;}
  .drclean-header .expand-collapse:before{ position:absolute; content:""; width:50px; height:50px; cursor:pointer; background:#d4d4d4; top:0px;right:0px;right: -23px;border-radius: 50%; z-index:-1;}
  
  .drclean-header .expand-collapse[data-toggle="collapse"]:after {
      font-family: 'FontAwesome';
      content: "\f106";
      /* "play" icon */
      float: right;
      color: #ffffff;
      font-size: 23px;
      line-height: 22px;
      position:relative;
      top:0px;
      right:3px;
      /* rotate "play" icon from > (right arrow) to down arrow */
  }
  
  .drclean-header .expand-collapse[data-toggle="collapse"].collapsed:after {
      /* rotate "play" icon from > (right arrow) to ^ (up arrow) */
      -webkit-transform: rotate(180deg);
      -moz-transform: rotate(180deg);
      -ms-transform: rotate(180deg);
      -o-transform: rotate(180deg);
      transform: rotate(180deg);
      top:5px;
  }
  
  .drclean-header span img{
      width: 25px;
      border-radius: 50%;
      margin-right: 5px;
      height: 25px;
  }
  .drclean-body-one{
      margin: 7px 10px 0 0;
  }
  .drclean-body-one span{
      font-size:13px;
      color:#7a7a7a;
  }
  .drclean-body-two{
      margin:7px 10px 0 0;
  }
  .drclean-body-two span{
      font-size:13px;
      color:#000;
  }
  .drclean-body{
      padding: 0px 15px 10px;
  }
  
  .best-drclean {
      border:1px solid #555555; border-radius:41px; padding:0px 10px;
      max-height: 500px;
      }
  
  .price-wrapper {
      width: 100%;
      float: left;
      background: #ffffff;
      margin-top: -104px !important;
      /*min-height: 680px;*/
      border-radius: 10px;
      bottom: -113px;
      box-shadow: 0px 10px 21px 1px rgba(0,0,0,0.15);
      padding: 32px 0px;
      position: relative;
  }
  
  
  .best-drclean {
      border:1px solid #555555; border-radius:41px; padding:0px 10px;
      max-height: 500px;
      }
  
  .price-wrapper {
      width: 100%;
      float: left;
      background: #ffffff;
      margin-top: -104px !important;
      min-height: 122px;
      border-radius: 10px;
      bottom: -113px;
      box-shadow: 0px 10px 21px 1px rgba(0,0,0,0.15);
      padding: 0px;
      position: relative;
  }
  /* price section */
  
  
  
  /* offer section */
  .offers-list-banner{margin:30px 0;}
  .swiperwrap{height:250px !important;}
  .offers-list-banner a img{
      box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.2);
  }
  
  /* offer section */
  
  .app-section{
      background: url("../../../assets/img/shophome/appbackground.png");
      background-repeat: no-repeat;
      background-position: center;
      background-repeat: no-repeat;
      height:500px;
      margin-top:140px;
      position:relative;
  }
  .app-download-img img{
      width: 300px;
      position: absolute;
      right: 0px;
      top: 100px;
  }
  .app-download-img img.app-img{
      animation: floating1 8s infinite;
      -webkit-animation: floating1 8s infinite;
      -webkit-animation-delay: 1s;
      animation-delay: 1s;
      position: absolute;
      top: 70px;
  }
  @keyframes floating1 {
      0% {
          -webkit-transform: rotateX(0deg) translateY(0px);
          -moz-transform: rotateX(0deg) translateY(0px);
          -ms-transform: rotateX(0deg) translateY(0px);
          -o-transform: rotateX(0deg) translateY(0px);
          transform: rotateX(0deg) translateY(0px);
      }
      50% {
          -webkit-transform: rotateX(0deg) translateY(-30px);
          -moz-transform: rotateX(0deg) translateY(-30px);
          -ms-transform: rotateX(0deg) translateY(-30px);
          -o-transform: rotateX(0deg) translateY(-30px);
          transform: rotateX(0deg) translateY(-30px);
      }
      100% {
          -webkit-transform: rotateX(0deg) translateY(0px);
          -moz-transform: rotateX(0deg) translateY(0px);
          -ms-transform: rotateX(0deg) translateY(0px);
          -o-transform: rotateX(0deg) translateY(0px);
          transform: rotateX(0deg) translateY(0px);
      }
  }
  
  @-webkit-keyframes floating1 {
      0% {
          -webkit-transform: rotateX(0deg) translateY(0px);
          -moz-transform: rotateX(0deg) translateY(0px);
          -ms-transform: rotateX(0deg) translateY(0px);
          -o-transform: rotateX(0deg) translateY(0px);
          transform: rotateX(0deg) translateY(0px);
      }
      50% {
          -webkit-transform: rotateX(0deg) translateY(-30px);
          -moz-transform: rotateX(0deg) translateY(-30px);
          -ms-transform: rotateX(0deg) translateY(-30px);
          -o-transform: rotateX(0deg) translateY(-30px);
          transform: rotateX(0deg) translateY(-30px);
      }
  }
  
  .theme2 .app-section-title h2{
      font-size:2.8rem;
      color: #002240;
      padding-bottom: 15px;
  }
  .theme2 .app-section-title h2 span{
      color: #09b2dc;
      font-size: 2.8rem;
  }
  .theme2 .app-section-title p{
      font-size: 16px;
      max-width: 40%;
      margin: 0 auto;
      line-height: 25px;
  }
  .theme2 .app-section-title ul li{
      padding-right:15px;
  }
  
  /* footer section */
  .theme2 .footer-section{
      background:#012341;
      color:#fff;
  }
  .theme2 .footer-section p{
      font-size:14px;
  }
  .theme2 .hr-footer{
      border-color:#969696;
      max-width:40%;
      margin: 0 auto;
  }
  
  ul.social-network {
      list-style: none;
      display: inline;
      padding: 0;
  }
  ul.social-network li {
      display: inline;
      margin: 0 5px;
  }
  .social-network a.icoRss {
      background-color: #F56505;
  }
  .social-network a.icoRss:hover {
      background-color: #F56505;
  }
  .social-network a.icoFacebook {
      background-color:#3B5998;
  }
  .social-network a.icoFacebook:hover {
      background-color:#3B5998;
  }
  .social-network a.icoTwitter {
      background-color:#33ccff;
  }
  .social-network a.icoTwitter:hover {
      background-color:#33ccff;
  }
  .social-network a.icoGoogle {
      background-color:#BD3518;
  }
  .social-network a.icoGoogle:hover {
      background-color:#BD3518;
  }
  .social-network a.icoVimeo {
      background-color:#0590B8;
  }
  .social-network a.icoVimeo:hover {
      background-color:#0590B8;
  }
  .social-network a.icoLinkedin {
      background-color:#007bb7;
  }
  .social-network a.icoLinkedin:hover {
      background-color:#007bb7;
  }
  .social-network a.icoRss:hover i, .social-network a.icoFacebook:hover i, .social-network a.icoTwitter:hover i,
  .social-network a.icoGoogle:hover i, .social-network a.icoVimeo:hover i, .social-network a.icoLinkedin:hover i {
      color:#fff;
  }
  a.socialIcon:hover, .socialHoverClass {
      color:#44BCDD;
  }
  
  .social-circle li a {
      display:inline-block;
      position:relative;
      margin:0 auto 0 auto;
      -moz-border-radius:50%;
      -webkit-border-radius:50%;
      border-radius:50%;
      text-align:center;
      width: 50px;
      height: 50px;
      font-size:20px;
  }
  .social-circle li i {
      margin:0;
      line-height:50px;
      text-align: center;
  }
  
  .social-circle li a:hover i, .triggeredHover {
      -moz-transform: rotate(360deg);
      -webkit-transform: rotate(360deg);
      -ms--transform: rotate(360deg);
      transform: rotate(360deg);
      -webkit-transition: all 0.2s;
      -moz-transition: all 0.2s;
      -o-transition: all 0.2s;
      -ms-transition: all 0.2s;
      transition: all 0.2s;
  }
  .social-circle i {
      color: #fff;
      -webkit-transition: all 0.8s;
      -moz-transition: all 0.8s;
      -o-transition: all 0.8s;
      -ms-transition: all 0.8s;
      transition: all 0.8s;
  }
  .footer-first-section h5, .footer-first-section a{
      font-size:13px;
      color:#fff;
  }
  .showhide-footer i{
      background: #f7f7f7;
      color: #000;
      width: 50px;
      height: 50px;
      line-height: 50px;
      border-radius: 50%;
      font-size: 21px;
      position: relative;
      top: -20px;
      cursor:pointer;
      transition: all 0.2s ease-in-out;
      -webkit-transition: all 0.2s ease-in-out;
      -moz-transition: all 0.2s ease-in-out;
      -o-transition: all 0.2s ease-in-out;
      -ms-transition: all 0.2s ease-in-out;
  }
  .themenavbar {
      flex-grow: initial;
  }
  
  .theme2 .navbar-trans img.shop-logo{
      position: absolute;
      z-index: 1;
      max-height: 50px;
      top: 0px;
      transition: all 0.2s ease-in;
      -webkit-transition: all 0.2s ease-in;
      -moz-transition: all 0.2s ease-in;
      -o-transition: all 0.2s ease-in;
      -ms-transition: all 0.2s ease-in;
  }
  .top-nav-bar{
      position:relative;
  }
  .theme2 .navbar-trans img.shop-logo-txt{
      position: absolute;
      left:110px;
      top: -5px;
  }
  .theme2 .navbar-default.navbar-reduce img.shop-logo{
      max-height:50px;
  }
  .top-nav-bar .shop-logo-txt {
      left: 110px;
      top: 0px;
      position: absolute;
  }
  .navbar-default.navbar-reduce .shop-logo-txt {
      left: 70px;
      right:auto;
  }
  .offer-details{
      margin: 20px 0;
      padding: 0px 55px 0px 105px;
  }
  /* footer section */
  
  
  /*sticky bottom*/
  
  .sticky-bottom {
      transform: translateY(100%);
  }
  .sticky-show {
      transform: translateY(0px);
  }
  .sticky-bottom {
      background: rgba(9, 178, 220,0.77);
      bottom: 0;
      color: #ffffff;
      position: fixed;
      width: 100%;
      padding:7px 0px;
      transition: transform 0.5s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
      z-index: 9999;
  }
  
  .sticky-bottom p, .sticky-bottom a { margin-bottom: 0px; font-size: 18px; color: #ffffff; }
  .sticky-bottom a i { font-size: 32px; color: #ffffff; vertical-align: middle; }
  
  
  
  @media only screen and (max-width:1199px){
  
  
  .single-search-blk h1{ font-size:30px; }
  .single-search-blk h1 span{ font-weight:600;}
  .single-search-blk p{ font-size:16px; line-height:1.4em; margin-bottom:23px;}
  
  
    .banner-text h2 {
      width:320px;
    }
  
      .price-tab-left .nav-tabs .nav-link {
          font-size:14px;
          padding:14px;
      }
  
      .price-tab-right .slick-list .slick-track {
          display: block !important;
      }
  
  
      .header-nav{
          padding: 0 45px;
      }
      
      .sec-offer span.offer-first{
          padding-left: 175px;
      }
      .about-section{margin-top:80px;}
      .offer-first{margin-left:25px;}
      .banner-text h2{top:0px;right:60px;}
  }
  @media only screen and (max-width:991px){
  
  
  .single-search-blk p {
      font-size: 16px;
  }
  
  .single-search-blk h1 {
      font-size: 21px;
  }
  
    .banner-text h2 {
      width:257px;
    }
  
      .navbar-nav{
          float:left !important;
          padding-left: 15px;
      }
      .swiper-pagination-bullet{
          margin-right:5px;
      }
      .price-tab-left .nav-tabs .nav-link{
          font-size:12px;
          padding:14px;
      }
      .app-download-img img {
          width: 250px;
          bottom: -40px;
  
      }
      .app-download-img img.app-img{
          top:270px;
      }
      .header-nav{
          padding: 0 45px;
      }
  
      .banner-text{
          right:60px;
      }
      
      .offers .slick-slide img {
          width: auto;
          height: 250px !Important;
      }
      .sec-offer span.offer-first{
          padding-left: 0px;
      }
      .product-selection ul a li{
          margin: 15px 0;
      }
      .about-section{margin-top:105px;}
      .offer-first{margin-left:0px;}
      .theme2 .navbar-trans img.shop-logo{right:0px;}
      .header-nav p{padding:10px 0;}
      .top-nav-bar .shop-logo-txt {
          left: auto;
          right: 90px;
      }
      .banner-text h2 {
          top:50px;
          right: 0px;
      }
      .navbar-default.navbar-reduce .shop-logo-txt {
          left: auto;
          right: 50px;
      }
      .navbar-default.navbar-reduce img.shop-logo{
          top:-5px;
      }
  
      .app-section {
          margin-top: 230px;
      }
  }
  
  
  @media only screen and (min-width:768px) {
  
    .nav-tabs.nav-justified>li {
        display: table-cell;
        width: 1%;
    }
  
  }
  
  
  @media only screen and (max-width:767px){
  
  
      .header-nav{
          padding: 0 15px;
      }
      .theme2 section {
          padding: 70px 15px;
      }
      .section-banner{
          height:auto;
          background-position: 90% 0;
      }
      .header-nav p {
          padding: 8px 0 5px 0;
          text-align:left;
      }
      .sec-offer{
          text-align:left;
          margin-top:25px;
          display:inline-block;
      }
      .sec-offer span.check-offer{
          text-align: center;
          display: inline-block;
          padding-top: 5px;
      }
      .theme2 .price-section{
          padding: 40px 15px;
      }
  
  
      .drclean-header{
          text-align:center;
      }
      .drclean-header span.price-hrs
      {
          float: none !important;
          text-align: center;
          display: block;
          padding-top: 5px;
      }
      .app-section-title p{
          max-width:100%;
      }
      .price-tab-left {
          width: 100%;
          float: left;
      }
      .price-tab-left ul{
          width:100%;
          padding-right:0px;
      }
      .price-tab-right {
          width: 100%;
          margin-top: -50px;
          bottom:-50px;
      }
      .app-download-img img {
          width: 190px;
          bottom: 0px;
      }
      .app-download-img img.app-img{
          top: 290px;
      }
      .navbar-default.navbar-trans, .navbar-default.navbar-reduce{
          padding:15px;
      }
      .banner .slick-list .slick-slide img {
          width: 800px;
          height: 230px;
      }
      .custom-search-laundry {
          top: 0px;
          position:relative;
          width:100%;
          margin-top: -8px !important;
          margin: 0px;
      }
      section.offer-section{
          margin:0px;
      }
      .slideshow{
          margin-top:-15px;
      }
      .about-service{
          max-width:100%;
      }
      .slider{
          height:350px;
      }
      .variable .slick-slide img {
          width: auto;
          height: 320px;
          object-fit: cover;
          margin-left: 15px;
          border-radius: 10px;
      }
      .variable .slick-active img {
          box-shadow: 0px 11px 12px 0px rgba(0,0,0,0.25);
          transform: scale(1.04);
      }
      .offers .slick-slide img {
          width: auto;
          height: 200px !Important;
      }
      .product-selection ul a li{
          margin:20px 0;
      }
      .about-section{margin-top:0px;}
      .theme2 .navbar-trans img.shop-logo{
          right:0px;
      }
      .theme2 .navbar-trans img.shop-logo-txt{
          right:110px;
          left:auto;
      }
      .navbar-default.navbar-reduce img.shop-logo-txt {
          left: auto;
          right:70px;
      }
  
      .xs-mt-32 {
          margin-top:32px;
      }
  
      .xs-only {
      display: block;
      margin-bottom: 23px;
      text-align: center;
      }
  
  
  .single-search-blk .postcodeform input[type="submit"], .single-search-blk .postcodeform .btn {
      height: 55px;
      font-size: 16px;
  }
  
  .custom-search-laundry {
      position: relative;
      top: auto; 
      left: auto; 
      transform: translate(0%,0%);
      z-index: 1;
  }
  
  .custom-search-laundry .single-search-blk {
    padding:14px;
  }
  
  
  }
  @media only screen and (max-width:479px){
  
  .single-search-blk .postcodeform input[type="text"] {
      width: 60%;
  }
  
  
  .single-search-blk .postcodeform {
      width: 100%;
  }
  
  .single-search-blk .postcodeform input[type="text"] {
      height: 55px;
      padding: 0 15px;
      font-size: 16px;
  }
  
  .single-search-blk .postcodeform input[type="submit"], .single-search-blk .postcodeform .btn {
      width: 40%;
  }
  
      .header-nav .col-6
      {
          width:100%;
          max-width: 100%;
          flex: 100%;
      }
      .app-link li a img{
          width:80%;
      }
      .app-download-img img {
          width: 170px;
          top: -90px;
      }
      .app-download-img img.app-img{
          top:-90px;
      }
      .footer-first-section{
          padding:0rem !important;
      }
      .list-inline-item:not(:last-child) {
          margin-right: 0 !important;
      }
  
      .header-nav p {
          padding: 8px 0 5px 0;
          text-align:center;
          float:none !important;
      }
      .sec-offer {
          text-align: center;
          margin-top: 25px;
      }
      .sec-offer span.check-offer{
          float:none !important;
      }
      .offers .slick-slide img {
          width: auto;
          height: 140px !Important;
      }
      .offers .slick-dots {
          bottom: 25px;
      }
      .text-brand img {
          width: 220px;
      }
      .variable .slick-slide img {
          width: auto;
          height: 220px;
          object-fit: cover;
          margin-left: 15px;
          border-radius: 10px;
      }
      .variable .slick-active img {
          box-shadow: 0px 11px 12px 0px rgba(0,0,0,0.25);
          transform: scale(1.04);
      }
      .slider{
          height:220px;
      }
      .navbar-default.navbar-reduce img.shop-logo {
          margin-top: 0px;
      }
  }
  
  /* Customized CSS */
  .location-search .has-error {
      border: 1px solid red;
  }
  
  .hidden {
      display: none;
  }
  